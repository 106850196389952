<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
      />
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import { get } from 'vuex-pathify'
import Data from './PuestoServicioViewData'
import { nonEmpty } from '@/utils/templates'
import { TABLA } from '@/utils/consts'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [
        'codigo',
        'descripcion',
        'cliente_nombre',
        'tvia_descripcion',
        'calle',
        'num',
        'cp',
        'localidad',
        'provincia',
      ],
      showingDialogs: {
        moreInfo: false,
      },
      detailNames: {
        adjuntos: 'adjuntos',
        novedades: 'novedades',
        servicios: 'servicios',
      },
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    usuarioIdvigilante: get('usuario/idvigilante'),
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idpuesto_servicio
        item.title = `${item.descripcion} (${item.codigo})`
        item.subtitle = this.$options.filters.linebreaksBr(nonEmpty`
          ${item.cliente_nombre}
          ${item.tvia_descripcion} ${item.calle} ${item.localidad} ${item.cp} ${item.provincia}
        `)
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = `${this.item.dataset.descripcion} (${this.item.dataset.codigo})`
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectPuestoServicio(this, this.routeParams.idpuesto_servicio)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      const resp = await Data.selectDetails(
        this,
        this.routeParams.idpuesto_servicio,
        !!this.usuarioIdtecnico,
        !!this.usuarioIdcomercial,
        !!this.usuarioIdvigilante
      )
      // servicios
      if (this.hasViewPerm(this.permissions.servicio.id)) {
        const detailServicios = this.addDetail(
          this.detailNames.servicios, 'Servicios', 'Servicios del puesto', 'servicioVigilancia'
        )
        detailServicios.badge = resp.data.selectpuestoServicioServicioCount.result.dataset[0].count || 0
      }
      // ficheros
      if (this.hasViewPerm(this.permissions.puestoServicioAdjunto.id)) {
        const detailFicheros = this.addDetail(
          this.detailNames.adjuntos, 'Adjuntos', 'Fotografías y ficheros adjuntos', 'attach'
        )
        detailFicheros.badge = resp.data.selectFicheroCount.result.dataset[0].count || 0
      }
      // Novedades
      // No las cuento porque puede darse el caso de ser muchas
      this.addDetail(
        this.detailNames.novedades, 'Novedades', 'Novedades e incidencias registradas', 'novedad'
      )
    },
    clickDetail (data) {
      let detailRoute = {
        name: null
      }
      switch (data.detail.name) {
      case this.detailNames.servicios:
        detailRoute = {
          name:'puestosservicio__servicio-list',
          query: {
            idpuesto_servicio: this.routeParams.idpuesto_servicio,
          }
        }
        // detailRoute.name = 'puestosservicio__servicio-list'
        // detailRoute.query = {
        //   idpuesto_servicio: this.routeParams.idpuesto_servicio,
        // }
        break
      case this.detailNames.adjuntos:
        detailRoute = {
          name: 'ficheros__puestos-servicio-fichero-list',
          params: {
            id: this.routeParams.idpuesto_servicio,
            idtabla: TABLA.puesto_servicio.idtabla,
          },
        }
        break
      case this.detailNames.novedades:
        detailRoute = {
          name: 'puestosservicio__lparte-novedad-list',
          params: {
            idpuesto_servicio: this.routeParams.idpuesto_servicio,
          },
        }
        break
      default:
        console.error(`La opción ${data.detail.name} no está contemplada`);
      }
      if (detailRoute.name !== null) {
        this.$appRouter.push(detailRoute)
      }
    },
  }
}
</script>
